import request from "../axios";

export function batchQueryServerAdminApi(gameId, serverId) {
    return request({
        url: "/server/admin/query/batch",
        method: 'GET',
        params: {
            game_id: gameId,
            server_id: serverId,
        }
    })
}

export function batchQueryServerVipApi(gameId, serverId) {
    return request({
        url: "/server/vip/query/batch",
        method: 'GET',
        params: {
            game_id: gameId,
            server_id: serverId,
        }
    })
}

export function batchQueryServerBanApi(gameId, serverId) {
    return request({
        url: "/server/ban/query/batch",
        method: 'GET',
        params: {
            game_id: gameId,
            server_id: serverId,
        }
    })
}

export function batchQueryServerPlayerApi(gameId, serverId, detailed) {
    return request({
        url: "/server/player/query/batch",
        method: 'GET',
        params: {
            game_id: gameId,
            server_id: serverId,
            detailed: detailed
        }
    })
}